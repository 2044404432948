<template>
    <div>
        <el-dialog :visible="supplementModalLocal" title="Begär komplettering">
            <el-row>
                <el-col :span="16">
                    <el-input resize="none" :rows="11" type="textarea" placeholder="Kommentar" v-model="comment" />
                </el-col>
                <el-col :span="8">
                    <el-scrollbar :height="200">
                        <div class="flex flex-col space-y-2 items-stretch">
                            <el-tooltip v-for="(commentTemplate, index) in commentTemplates" :key="index" class="box-item" effect="dark" :content="commentTemplate.tooltip" placement="left-end">
                                <el-button class="ml-4" type="primary" @click="comment = commentTemplate.content">{{ commentTemplate.title }}</el-button>
                            </el-tooltip>
                        </div>
                    </el-scrollbar>
                </el-col>
            </el-row>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeSupplementModal">Avbryt</el-button>
                    <el-button type="primary" @click="sendSupplement">
                        Skicka
                    </el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog :visible="discardModal" title="Bekräfta makulering">
            <el-input type="textarea" placeholder="Kommentar" v-model="comment" />
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeDiscardModal">Avbryt</el-button>
                    <el-button type="primary" @click="discardReceipt">
                        Skicka
                    </el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog :visible="denyModal" title="Neka kvitto">
            <el-input type="textarea" placeholder="Kommentar" v-model="comment" />
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeDenyModal">Avbryt</el-button>
                    <el-button type="primary" @click="denyReceipt">
                        Skicka
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["supplementModal", "discardModal", "denyModal"],
    watch: {
        supplementModal: {
            handler() {
                this.supplementModalLocal = this.supplementModal;
            },
            immediate: true,
        },
        discardModal: {
            handler() {
                this.discardModalLocal = this.discardModal;
            },
            immediate: true,
        },
        denyModal: {
            handler() {
                this.denyModalLocal = this.denyModal;
            },
            immediate: true,
        },
    },
    data: function() {
        return {
            comment: "",
            commentTemplates: [
                {
                    title: "Bilaga inte kvitto",
                    tooltip: "Infogad bilaga är inte ett kvitto, bifoga kvittot.",
                    content: "Infogad bilaga är inte ett kvitto, bifoga kvittot.",
                },
                {
                    title: "Kvitto datumet stämmer inte",
                    tooltip: "Kvitto datumet stämmer inte överens med bilagan",
                    content: "Kvitto datumet stämmer inte överens med bilagan",
                },
                {
                    title: "Belopp stämmer inte överens",
                    tooltip: "Hej, beloppen överensstämmer inte med varandra - vad ska gälla?",
                    content: "Hej, beloppen överensstämmer inte med varandra - vad ska gälla?",
                },
                {
                    title: "Fyll i deltagare",
                    tooltip: "Vänligen kryssa representation och fyll i deltagarnas namn.",
                    content: "Vänligen kryssa representation och fyll i deltagarnas namn.",
                },
                {
                    title: "Uber utan moms",
                    tooltip: "Vänligen bifoga underlag från Uber där moms framgår. Du finner detta under 'Mina resor' på Ubers hemsida.",
                    content: "Vänligen bifoga underlag från Uber där moms framgår. Du finner detta under 'Mina resor' på Ubers hemsida.",
                },
                {
                    title: "Arbetskläder",
                    tooltip: "För att få göra avdrag för arbetskläder måste minst en av Skatteverkets följande krav vara uppfyllda...",
                    content: `För att få göra avdrag för arbetskläder måste minst en av Skatteverkets följande krav vara uppfyllda: 

1. Uniformer och andra arbetskläder. Om inköpet avser kläder som är anpassade för arbetsuppgifterna och inte lämpligen kan användas privat.
2. Skyddskläder och liknande. Det gäller kläder som utsätts för kraftigt slitage, exempelvis skor med stålhätta. 
3. Fria arbetskläder, som även lämpar sig för privat bruk, om arbetsgivarens namn, symbol eller logotyp permanent fastsatt på kläderna.

Önskar du ändå att låta företaget stå för kostanden? `,
                },
            ],
            supplementModalLocal: false,
            discardModalLocal: false,
            denyModalLocal: false,
        };
    },
    methods: {
        closeSupplementModal() {
            this.$emit("closeSupplementModal");
        },
        closeDiscardModal() {
            this.$emit("closeDiscardModal");
        },
        closeDenyModal() {
            this.$emit("closeDenyModal");
        },
        sendSupplement() {
            this.$emit("supplement", this.comment);
        },
        discardReceipt() {
            this.$emit("discard", this.comment);
        },
        denyReceipt() {
            this.$emit("deny", this.comment);
        },
    },
};
</script>
